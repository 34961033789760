.filter-button {
  border: 1px solid #6c8ba6 !important;
  align-items: center;
  height: 40px !important;
  padding-top: 4px;
  color: #286BA6 !important;

  .text-filter {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #286ba6;
  }

  .number {
    background: #137db9;
    border-radius: 2px;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height, or 130% */

    /* Neutral/Neutral 100 */

    color: #ffffff;
    padding: 2px;
  }
}

.btn-custom {
  height: 32px;
  border-radius: 4px !important;
  width: fit-content;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 22px;

  img {
    // margin-bottom: 4px;
    padding-right: 4px;
  }
}

.btn-custom:disabled {
  background: #f0f0f4 !important;
  border-color: #f0f0f4 !important;

  span {
    color: #aaabae !important;
  }
}

.btn-custom-main {
  background: var(--main-bg-btn-color) !important;
  color: white;
}

.btn-custom-main:hover {
  color: white !important;
  // border: 1px solid var(--main-bg-btn-color-hover) !important;
  // background: var(--main-bg-btn-color-hover) !important;
}

.btn-hover-main:hover {
  border: 1px solid var(--main-bg-btn-color) !important;
  color: var(--main-bg-btn-color) !important;
  background: white;
}

.input-custom:focus,
.input-custom:hover {
  border: 1px solid var(--main-bg-btn-color) !important;
}

.blue-button-bordered {
  border: 1px solid #286ba6;
}

.blue-button {
  height: 32px;
  /* Primary/Primary 40 */

  background: #286ba6;

  border-radius: 2px;

  span {
    // font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* Neutral/Neutral 100 */

    color: #ffffff;
  }
}

.blue-button:hover {
  background: #286ba6 !important;
}

.red-button {
  height: 32px;
  /* Primary/Primary 40 */

  background: #de372f;
  border-radius: 2px;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* Neutral/Neutral 100 */

    color: #ffffff;
  }
}

.red-button:hover {
  background: #ff5449;
}

.border-button {
  height: 32px;

  /* Neutral/Neutral 100 */

  background: #ffffff;
  /* Neutral/Neutral 90 */

  border: 1px solid #286BA6;
  border-radius: 2px;

  span {
    // font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* Neutral/Neutral 10 */

    color: #286BA6;
  }
}

.border-button:hover {
  span {
    color: #137db9;
  }
}

.border-blue-button {
  height: 32px;

  /* Neutral/Neutral 100 */

  background: #ffffff;
  /* Neutral/Neutral 90 */

  border: 1px solid #006699;
  border-radius: 2px;

  span {
    // font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    font-family: "robotoMeium";
    /* identical to box height, or 123% */

    /* Neutral/Neutral 10 */

    color: #006699;
  }
}

.border-red-button {
  height: 32px;

  /* Neutral/Neutral 100 */

  background: #ffffff;
  /* Neutral/Neutral 90 */

  border: 1px solid red;
  border-radius: 2px;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* Neutral/Neutral 10 */

    color: red;
  }
}

.border-button-excel {
  height: 32px;
  background: #ffffff;

  border: 1px solid #006699;
  border-radius: 0;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #006699;
    padding-left: 5px;
  }
}

.ant-btn-default:disabled {
  span {
    color: black;
  }
}

.ant-btn-default:disabled:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.border-red-button-delete {
  height: 32px;

  /* Neutral/Neutral 100 */

  background: #ffffff;
  /* Neutral/Neutral 90 */

  border: 1px solid red;
  border-radius: 2px;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* Neutral/Neutral 10 */

    color: red;
  }
}

.border-red-button-delete:hover {
  background-color: red;
  border: 1px solid red;

  span {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height, or 123% */

    /* Neutral/Neutral 10 */

    color: white;
  }
}

.btn-action-ams {
  height: 32px;
  font-size: 13px !important;
  width: fit-content;

  img {
    margin-bottom: 4px;
  }

  border: none !important;
  background-color: transparent;
}

.btn-action-ams:disabled {
  span {
    color: #aaabae !important;
  }

  opacity: 0.6;
}

.btn-action-ams:hover {
  border: none !important;
}

.create-btn-ams {
  height: 40px !important;
  width: 119px !important;
}