@import url("./mainColor.scss");
@import url("./btn.scss");
@import url("./popup.scss");
@import url("./pagionation.scss");
@import url("./statusColor.scss");
@import url("./uploadFile.scss");
@import url("./nav.scss");
@import url("./table.scss");
@import url("./searchInput.scss");
@import url("./controlbase.scss");
@import url("./breadcrumb.scss");
@import url("./button.scss");
@import url("./form.scss");
a {
  text-decoration: none;
}

.back-comp {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  /* On surface */

  color: #212121;
}

.navbar-mes {
  height: 52px;

  .ant-menu-item {
    display: flex;
    align-items: center;

    // padding: 0;
    .ant-menu-title-content {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height, or 123% */

      /* Neutral/Neutral 10 */

      color: #1a1c1e;
    }
  }

  .ant-menu-item-selected {
    .ant-menu-title-content {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 123% */

      /* Primary/Primary 40 */

      color: #006496 !important;
    }
  }

  .ant-menu-item-selected::after {
    border-width: 3px !important;
    border-bottom-color: #006496 !important;
    inset-inline: 0px !important;
  }
}

.form-mes {
  .ant-form-item-label label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #1a1c1e;
  }
}

.cursor-pointer {
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-1i536d8) {
  font-family: "mulish" !important;
  font-style: normal;
  font-size: 16px;
}

.action-btn-box {
  position: absolute;
  bottom: 0px;
  background: white;
  width: 100%;
  left: 0;
  height: 50px;
  display: flex;
  align-items: center;
}

#loading-main {
  opacity: 0.5;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, black, transparent);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    margin: auto;
    border: 20px solid aliceblue;
    border-radius: 50%;
    border-top: 20px solid blue;
    width: 130px;
    height: 130px;
    animation: spinner 4s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -100px;
  }

  .vinm-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.vinm-spinner div {
  transform-origin: 40px 40px;
  animation: vinm-spinner 1.2s linear infinite;
}
.vinm-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.vinm-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.vinm-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.vinm-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.vinm-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.vinm-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.vinm-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.vinm-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.vinm-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.vinm-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.vinm-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.vinm-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.vinm-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes vinm-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

#page-display {
  height: 100%;
}
