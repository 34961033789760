@font-face {
  font-family: "mulishBold";
  src: url("../../../public/Mulish/Mulish/Mulish-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "mulish";
  src: url("../../../public/Mulish/Mulish/Mulish-Regular.ttf");
  font-style: "Regular";
}

@font-face {
  font-family: "mulishSemiBold";
  src: url("../../../public/Mulish/Mulish/Mulish-SemiBold.ttf");
  font-style: "semibold";
}

@font-face {
  font-family: "robotoMeium";
  //src: url("../../../public/Mulish/roboto/Roboto-Medium-webfont.woff");
  src: url("../../../public/Mulish/roboto/Roboto-Medium.woff");
  font-style: "medium";
}

@font-face {
  font-family: "roboto";
  //src: url("../../../public/Mulish/roboto/Roboto-Regular-webfont.woff");
  src: url("../../../public/Mulish/roboto/Roboto-Regular.woff");
  font-style: "regular";
}

body,
html,
.ant-form-item-control,
.ant-menu,
.ant-table,
.ant-pagination,
.ant-pagination-item,
.ant-select-selector,
.ant-btn,
.ant-layout,
.ant-input .label-input {
  font-family: "Roboto";
  font-style: normal;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.container-app {
  max-width: 100%;
  padding: 16px;
  margin: 0;
  height: 100%;
  max-height: calc(100vh - 64px - 22px - 32px - 60px);
  overflow: auto;

  .main-body {
    background: white;
    padding: 24px;
    margin: 0;
    height: auto;
    width: calc(100% - 10px);
    float: right;
    border-radius: 8px;
  }
}

.form-filter-input {
  width: 320px;
  float: left;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #286ba6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #286ba6;
}

.back-to {
  // height: 55px;
  display: flex;
  //border-bottom: 0.5px solid lightgray;
  // margin-bottom: 12px;

  .back-to-page {
    margin-top: 7px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */

    /* Neutral/Neutral 10 */

    color: #1a1c1e;
  }

  hr {
    display: none;
  }

  .page-title {
    color: #286ba6;
    font-size: 24px;
    font-family: "robotoMeium";
    border-left: 1px solid #9b9b9b;
    padding-left: 12px;
    line-height: 28px;
    display: inline-block;
  }

  .back-button {
    display: flex;
  }
}

.cursor {
  cursor: pointer;
}

.error {
  color: #ff4d4f;
}

.onClickTr {
  td {
    background-color: #e6f4ff !important;
  }
}

// common spacing
.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

@media print {
  @page {
    size: auto !important;
  }
}

.batch-form-item {
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: flex-start;

    &>*:first-child {
      flex: 1 1 0;
    }
  }
}

.custom-ant-button-outlined {
  border: 1px solid #286ba6;
  color: #286ba6;
}