$primary: #286BA6;

.mes-sidebar {
  background: #fff !important;
  /* Card */
  height: 100vh;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25) !important;

  
  .logo-mes-collapsed {
    height: 64px;
    align-items: center;
    display: flex;
    margin-left: 7px;
  }

  .logo-mes {
    height: 64px;
    align-items: center;
    display: flex;
    position: relative;
    margin: 32px auto 28px auto;

    .toggle {
      position: absolute;
      /* z-index: 123; */
      right: 0;
    }

    .toggle-responsive {
      margin: 0 auto;
      display: block;
    }
  }

  .ant-layout-sider-trigger {
    display: none;
  }

  .ant-menu::-webkit-scrollbar-thumb {
    background: #fff !important;
    border-radius: 0 !important;
  }

  .ant-menu::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
}

.ant-menu-custom-sidebar-collapsed {
  .ant-menu-vertical {
    background: white !important;
  
    .ant-menu-item {
      padding-right: 16px !important;
    }
  }
}

.ant-menu-custom-sidebar-expanded,
.ant-menu-custom-sidebar-collapsed {
  border-radius: 0;
  background: #fff;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25) !important;

  .ant-menu-title-content {
    // font-weight: 400;
    font-size: 14px;
    color: #6c8ba6;
  }

  ul li {
    .ant-menu-title-content {
      color: #464646;
    }
  }

  .ant-menu-submenu-title {
    border-radius: 0;
    padding-inline-end: 0px;
    padding-inline-start: 14px;
    display: flex;
    align-items: center;
  }

  .ant-menu-item {
    border-radius: 0px !important;
    padding-left: 12px !important;
    padding-right: 0 !important;
  }

  .ant-menu-item[to="/trang-chu"] {
    img {
      width: 16px;
    }
  }

  .ant-menu-item[level="2"] {
    .ant-menu-title-content {
      padding-left: 10px !important;

    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 8px !important;

      .ant-menu-submenu-arrow {
        display: none;
      }
    }

    .ant-menu-item {
      padding-left: 42px !important;
    }

    .ant-menu {
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 42px !important;
          margin-left: 0px;
        }
      }
    }

    .ant-menu-submenu-arrow {
      color: #464646;
    }
  }

  .ant-menu-submenu[level="1"] {
    .ant-menu-submenu-arrow {
      display: block;
    }
  }

  .ant-menu-item-selected {
    background: #286BA6 !important;
  }

  .ant-menu-item-active {
    background: #DCDCDC;
  }

  .ant-menu-submenu-title[aria-expanded="true"] {
    background: #fff !important;

    // border-left: 3px solid #006496;
    .ant-menu-title-content {
      color: $primary;
    }

    .ant-menu-submenu-arrow {
      color: $primary
    }
  }
}

.menu-home {
  .ant-menu-title-content {
    color: #6C8BA6;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }

  .ant-menu-item-icon {
    filter: brightness(0) saturate(100%) invert(52%) sepia(56%) saturate(228%) hue-rotate(166deg) brightness(91%) contrast(84%);
  }
}

.custom-not-selected {
  ul {
    li {
      .ant-menu-title-content {
        color: #9B9B9B;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.ant-layout-sider-collapsed {
  width: 120px;
  min-width: 120px !important;

  .ant-menu-item {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-menu-item[to="/trang-chu"] {
    img {
      margin-left: 3px;
      width: 16px;
    }
  }

  .ant-layout-sider-children {
    .ant-menu {
      .ant-menu-item {
        width: 100%;
        margin: 0;

        img.ant-menu-item-icon {
          width: 16px;
          // margin-right: 7px;
        }

        .ant-menu-title-content {
          display: none;
        }
      }

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 28px !important;
        }

        div.ant-menu-submenu-title {
          padding: 0 !important;
          margin: 0 !important;
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .ant-menu-title-content {
          display: none;
        }
      }
    }
  }
}

.toggle {
  position: absolute !important;
  z-index: 123 !important;
  right: -50px;
  top: 5px;
}

.toggle-collapse {
  margin: 10px auto auto 10px;
}

.ant-layout-sider {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12) !important;
  border: none !important;
  z-index: 100;

  .ant-menu-item {
    width: 100%;
    margin: 0;
  }
}

.ant-layout-header {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12) !important;
}

.ant-menu-level-1 {
  .ant-menu-title-content {
    font-weight: 400;
    font-size: 13px;
    color: #292940 !important;
  }

  .ant-menu-submenu-arrow {
    display: none;
  }
}

// ** collapsed button **
.btn-collapse-wrap {
  margin: 25px 0px;
  transform: rotate(90deg);
  position: absolute;
  bottom: 50%;
  right: -30px;
  z-index: 100;
}

.btn-collapse {
  font-family: sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  background: #fff;
}

.btn-trapezoid-outline {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  ;
  transform-style: preserve-3d;
  padding: 5px 29px;
  transform: perspective(200px) rotateX(45deg) translateZ(25px) translateY(5px);
  -webkit-backface-visibility: hidden;
  transition: all .3s ease-out;

  span {
    font-size: 15px;
    letter-spacing: 3px;
    display: block;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
}

.ant-menu-item[to="/trang-chu"] {
  img {
    margin-left: 3px;
    width: 16px;
  }
}