.ant-form-item-label {
    label {
        font-weight: 500;
        font-size: 14px;
    }
}

.form-search-input {
    .ant-input-search-button {
        background: #E3E3E3;
        width: 98px;
        height: 32px;
        color: #606771;
        border: none;
        border-radius: 0px 2px 2px 0px;
        box-shadow: none;
        font-weight: 400;
        font-size: 14px;
    }
}

.formSearch {
    background: #f5f5f5;
    padding: 10px;
    margin-left: 0px;
}