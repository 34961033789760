.popup-main {
  .ant-modal-content {
    border-radius: 2px !important;
    padding: 0;

    .ant-modal-header {
      padding: 22px 24px;
      border-bottom: 1px solid #e6e6e6;

      //css text inside
      .ant-modal-title {
        font-weight: 700;
        font-size: 15px;
        color: #1a1a1a;
      }
    }

    .ant-modal-body {
      padding: 0 24px 24px;
    }

    .ant-modal-footer {
      border-top: 1px solid #e6e6e6;
      padding: 16px 24px;
    }
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.modal-confirm {
  .header-confirm {
    padding-top: 20px;

    .header-confirm-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: #286ba6;
      font-family: "robotoMeium";
    }
  }

  .ant-modal-content {
    border-radius: 12px !important;
  }

  .line-modal {
    border-top: 1px solid #6c8ba6;
    opacity: 1;
  }

  .input-reason {
    .ant-row {
      display: block !important;
    }

    .label-input {
      color: #333333;
      font-family: "robotoMeium";
    }
  }

  .ant-modal-close {
    display: none !important;
  }

  .w-10 {
    width: 100px;
  }
}

.popup-main-delete {
  width: 25% !important;
  height: 70%;

  .ant-modal-content {
    border-radius: 12px !important;
    padding: 0;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      padding: 22px 22px 0 22px;

      //css text inside
      .ant-modal-title {
        font-family: 'robotoMeium';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #286BA6
      }
    }

    .ant-modal-body {
      padding: 0 24px 24px;

      .delete-des {
        font-family: 'robotoMeium';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
      }

      .btn-delete-modal {
        box-shadow: none;
        display: flex;
        justify-content: center;

        .img-delete {
          width: 12%;
        }
      }

      .delete-name {
        font-family: 'robotoMeium';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #333333;
      }
    }

    .ant-modal-footer {
      padding: 0 24px 24px;
      margin-top: 0;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      .btn-delete-footer {
        width: 137.5px;
        height: 30px;
      }
    }
  }
}

.d-flex-center {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}