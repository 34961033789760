$primary: #286BA6;
.topbar-mes {
    min-height: 64px !important;
    height: auto !important;
    background-color: white !important;
    z-index: 2 !important;
    .topbar {
        height: 64px;
        background:$primary;
        .left {
            align-items: center;
            .topbar-title-side {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 20px;
                /* identical to box height, or 133% */

                /* Neutral/Neutral 10 */
                white-space: nowrap;
                //overflow: hidden;
                text-overflow: ellipsis;
                color: #1a1c1e;
            }
        }
        .right {
            align-items: center;
            justify-content: end;
            position: relative;
            .notification-main {
                position: relative;
                .bell-notification {
                    border: 1px solid red;
                    border-radius: 30px;
                    background: red;
                    color: white;
                    line-height: 20px;
                    width: 20px;
                    text-align: center;
                    position: absolute;
                    top: 9px;
                    right: -8px;
                }
            }
            .avantar-button {
                height: 32px;
                border: 1px solid #e2e2e5;
                border-radius: 2px;
                align-items: center;
                display: flex;
            }
        }
    }
}
.notification-menu {
    background-color: white;
    height: 400px;
    overflow: hidden auto;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    width: 350px;
    padding: 16px;
    .noti-title {
        display: flex;
        justify-content: space-between;
        .left {
            font-size: 20px;
            font-weight: 600;
        }
    }
    .item {
        display: flex;
        padding: 16px 0;
        align-items: center;
        .avatar {
            height: 46px;
            width: 46px;
            border-radius: 40px;
            margin-right: 16px;
            position: relative;
        }

        .unread {
            background-color: #001529;
        }
        .unread::after {
            display: block;
            content: " ";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: red;
            position: absolute;
            right: 0px;
        }
        .readed {
            background-color: lightgrey;
        }
        .text {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 70%;
            height: 1.2em;
            white-space: nowrap;
        }
    }
}
.something-new {
    position: relative;
}
.something-new::after {
    display: block;
    content: " ";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: red;
    position: absolute;
    right: 16px;
    top: 4px;
}
.change-language-img {
    height: 32px;
    border: 1px solid #e2e2e5;
    border-radius: 2px;
    align-items: center;
    display: flex;
    img {
    }
}

.language-button {
    margin-left: 4px;
}