.mes-table {

  .ant-table-thead>tr>td {
    background: #f0f0f4 !important;
  }

  .ant-table-header {
    border-radius: 0px !important;
  }

  thead {
    tr {

      th,
      td {
        padding: 7.5px 10px !important;
        font-size: 13px;
        color: #1a1c1e !important;
        background: #f0f0f4 !important;
        border-start-start-radius: 0 !important;
        border-start-end-radius: 0 !important;

        .ant-table-column-title {
          font-weight: 700;
          line-height: 16px;
        }
      }

      th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }
    }

    .ant-table-cell {}
  }

  tbody {
    tr {
      td {
        font-size: 13px;
        padding: 0px 10px !important;
        color: #1a1c1e !important;

        img.icon-base {
          width: 15px;
        }
      }

      td.action {
        padding: 2px !important;
      }

      td.ant-table-cell-row-hover {
        background: #f0f0f4 !important;
        border-radius: 0px !important;
      }
    }
  }

  // .ant-table-body::-webkit-scrollbar-track {
  //     background: white;
  // }
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #9aa4bb;
    border-radius: 0px;
  }

  .ant-table-body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

// css scroll background color?
.hide-scroll {
  .ant-table-body {
    overflow-y: hidden !important;
  }

  // .ant-table-cell-scrollbar {
  //     display: none;
  // }
}

.ant-pagination {
  .ant-pagination-item {
    border-color: #e2e2e5;
    border-radius: 2px;
  }

  .ant-pagination-item.ant-pagination-item-active {
    // border-color: var(--main-bg-btn-color);
    border: 1px solid #286BA6;
    background: #286BA6;
    color: #fff;
  }

  .ant-pagination-item:hover {
    //border: 1px solid var(--main-bg-btn-color);
    border: 1px solid #286BA6;
  }

  .ant-pagination-item.ant-pagination-item-active:hover a {
    color: #fff;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    line-height: 10px;
    border: 1px solid #9B9B9B;
    border-radius: 2px;
  }

  .ant-pagination-next:hover,
  .ant-pagination-prev:hover {
    // border: 1px solid var(--main-bg-btn-color);
    border: 1px solid #286BA6;
  }

  .ant-pagination-options {
    .ant-select-selector {
      border-radius: 2px;
    }

    :where(.css-dev-only-do-not-override-1hyej8k).ant-select:hover .ant-select-selector {
      border-color: var(--main-bg-btn-color) !important;
    }
  }
}

.filter-dropdown {
  width: 511px;
  margin: 0 auto;
  background-color: white;
  // padding: 10px 24px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));

  .title {
    padding: 16px;
    height: 52px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Character/Title .85 */

    color: rgba(0, 0, 0, 0.85);
    box-shadow: inset 0px -1px 0px #f0f0f0;
  }

  .form {
    padding: 12px 30px;
  }

  .form-group {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }

  .form-group_button {
    padding: 16px 0px;
  }
}

.paging-fixed {
  position: fixed;
  bottom: 10px;
  right: 48px;
  //width: 100%;
  z-index: 99;
}

.paging-fixed-detail {
  position: fixed;
  bottom: 70px;
  right: 32px;
  width: 100%;
  z-index: 99;
}

/// Để hiển thị dữ liệu grid thành 1 dòng
.text-cell-one {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-full {
  flex: 1;
  display: -webkit-flex;
  flex-direction: column;
  height: 100%;
}

.flex-table {
  -webkit-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
}

.grid-list-container {
  .ant-spin-nested-loading {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;

    .ant-spin-container {
      flex: 1 1;
      display: flex;

      .ant-table {
        flex: 1 1;
        display: flex;
        min-width: 0;
        // max-height: 50vh;

        .ant-table-container {
          flex: 1 1;
          display: flex;
          flex-direction: column;
          min-width: 0;

          .ant-table-body {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            // padding-bottom: 14px;
            // max-height: calc(100% - 200px) !important;
          }
        }
      }
    }
  }

  .ant-table-placeholder {
    .table-error {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.custom-table-with-input-inside {
  tbody {
    th {
      td {
        height: 50px !important;
      }
    }
  }
}

.ams-table {
  table {
    // border-collapse: collapse;
    border-radius: 4px;
    //overflow: hidden;
    table-layout: fixed !important;
    //background-color: #6c8ba6;

    // tr:last-child td:first-child {
    //   border-bottom-left-radius: 4px;
    // }

    // tr:last-child td:last-child {
    //   border-bottom-right-radius: 4px;
    // }
  }


  .ant-table-thead .ant-table-cell {
    background-color: #6c8ba6 !important;
    color: #ffffff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }


  .ant-table-thead>tr>td {
    background: #f0f0f4 !important;
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: 0 1px 0 1px #f0f0f4;
  }

  .ant-table-header {
    border-radius: 0px !important;
  }

  thead {
    tr {

      th,
      td {
        padding: 7.5px 10px !important;
        font-size: 13px;
        background: #f0f0f0 !important;
        border-start-start-radius: 0 !important;
        border-start-end-radius: 0 !important;

        .ant-table-column-title {
          font-weight: 700;
          line-height: 16px;
        }
      }

      th:first-child {
        border-top-left-radius: 4px !important;
      }

      th:last-child {
        border-top-right-radius: 4px !important;
      }



      // th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      //   display: none;
      // }
    }
  }

  tbody {
    tr {
      td {
        font-size: 13px;
        padding: 0px 10px !important;
        color: #333 !important;
        background: #fff;


        img.icon-base {
          width: 15px;
        }
      }

      td.action {
        padding: 2px !important;
      }

      td.ant-table-cell-row-hover {
        background: #f0f0f4 !important;
        border-radius: 0px !important;
      }

    }

    td:last-child {
      border-inline-end: 1px solid #6c8ba6 !important;
    }

    :last-child td:first-child {
      border-bottom-left-radius: 4px !important;
    }

    :last-child td:last-child {
      border-bottom-right-radius: 4px !important;
    }
  }

  // .ant-table-body::-webkit-scrollbar-track {
  //     background: white;
  // }
  .ant-table-body::-webkit-scrollbar-track {
    border-radius: 8px;
    -webkit-border-radius: 8px;
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background: #286BA6;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    border: 2px solid #f5f5f5;
  }

  .ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  td,
  th {
    border-bottom: 1px solid #6c8ba6 !important;
    border-inline-start: 1px solid #6c8ba6 !important;
  }

}

.total-table {
  color: blue;
}

.ant-table-wrapper table {
  border-radius: 0 !important;
}

th.text-algin-center-th{
  text-align: center !important;
}

.font-roboto-medium{
  font-family: "robotoMeium";
}