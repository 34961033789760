.menu-label {
  color: #6c8ba6;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  user-select: none;

  &-selected {
    color: #286ba6;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
  }
}

.menu-icon {
  width: 20px;
  height: auto;

  filter: brightness(0) saturate(100%) invert(50%) sepia(43%) saturate(267%)
    hue-rotate(166deg) brightness(97%) contrast(94%);

  &-selected {
    filter: brightness(0) saturate(100%) invert(37%) sepia(7%) saturate(5893%)
      hue-rotate(169deg) brightness(96%) contrast(86%);
  }
}

:global(.ant-menu-item-selected) {
  .menu-label-selected {
    font-weight: normal;
    color: white;
  }

  .menu-icon-selected {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2%)
      hue-rotate(290deg) brightness(118%) contrast(100%);
  }
}

:global(.ant-menu-custom-sidebar-collapsed .ant-menu-root) {
  .menu-icon {
    filter: brightness(0) saturate(100%) invert(50%) sepia(43%) saturate(267%)
      hue-rotate(166deg) brightness(97%) contrast(94%);
  
    &-selected {
      filter: brightness(0) saturate(100%) invert(37%) sepia(7%) saturate(5893%)
        hue-rotate(169deg) brightness(96%) contrast(86%);
    }
  }
}

:global(.ant-menu-custom-sidebar-collapsed) {
  .menu-label {
    display: inline;
  }
}