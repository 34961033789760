.file-display {
  .upload-file-show-custom {
    border: 1px solid #d9d9d9;
    max-width: 240px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-right: 12px;
    height: 32px;
    a {
      display: flex;
    }
    .text-file {
      width: 68%;
      overflow: hidden;
    }
    .image-show {
      width: 32px;
      height: 32px;
    }
    .document {
      position: relative;
      left: 9px;
    }
    .trash {
      right: 8px;
    }
  }
}
