.icon-base {
  width: 30px;
}

textarea {
  border: 1px solid #e2e2e5;
}

.input-mes {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-family: "Roboto";
  // .ant-select-selector {
  //   border-radius: 0 !important;
  //   border: 0px solid #d9d9d9 !important;
  //   height: 30px !important;
  // }

  .ant-picker-suffix {
    color: black;
  }

  .ant-input-prefix {
    padding-right: 6px;
  }

  .ant-input-number-group-addon {
    border-radius: 0;
  }

  .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.88) !important;
  }

  .ant-picker-input {
    input {
      color: black !important;
    }
  }

  .ant-select-arrow {
    color: black;
  }

}



.input-mes:focus,
.input-mes:hover {
  border: 1px solid var(--main-bg-btn-color) !important;
}

.input-mes:disabled {
  color: #333 !important;
}

.input-search {
  width: 500px !important;
}

.form-input {
  margin-bottom: 12px;

  label {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px;
    color: #212121 !important;
  }
}

.label-input {
  // font-weight: 400;
  font-size: 14px;
  color: #333333;
  font-family: "robotoMeium";
}

.textarea-mes {
  border: 1px solid #BFBFBF;
  border-radius: 2px !important;
}



.required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}

.required-before::before {
  content: "*";
  color: red;
  margin-left: 3px;
}

.error {
  font-size: 13px !important;
}

.plusIcon-select {
  right: 42px;
  top: 10px;
}

.custom-checkbox {

  // font-weight: 700;
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #006496;
      border-color: #006496;
    }
  }

  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: rgb(255 255 255 / 100%);
  }
}

.custom-ratio .ant-radio-checked .ant-radio-inner {
  border-color: #48bb78;
  background-color: #48bb78;
}

.ant-select-status-error .ant-select-selector {
  border: 1px solid red !important;
}

.ant-table-footer {
  padding: 10px !important;
}

.form-wrapper {
  .ant-form-item-row {
    display: flex;
    align-items: center;
  }

  .ant-form-item-label {
    white-space: unset;
  }

  .ant-form-item-label label {
    color: #333;
    line-height: 15px;
    text-align: start;
    font-family: 'robotoMeium';
    // width: 150px;

    &::after {
      content: "";
    }
  }

  .ant-form-item {
    margin-bottom: 15px;
  }
}

.divider-line {
  margin: 15px 0 !important;
  color: #E9E9E9 !important;
}

.action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px auto;
  align-items: center !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #BFBFBF !important;
  font-style: italic;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #BFBFBF !important;
  font-style: italic !important;
}

::placeholder {
  color: #BFBFBF !important;
  font-style: italic !important;
}

.select-mes {
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  height: 40px;
  overflow: hidden;
  background: #fff;

  .ant-select-selector {
    border-radius: 0 !important;
    border: 0px solid #d9d9d9 !important;
    height: 40px !important;
    box-shadow: none !important;
    padding: 5px 11px !important;
    font-style: italic;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-family: "Roboto";
    color: #333 !important;

  }

  // .ant-input-prefix {
  //   padding-right: 6px;
  // }

  // .ant-input-number-group-addon {
  //   border-radius: 0;
  // }
  .ant-select-arrow {
    color: #9B9B9B;
  }

  .ant-select-selection-search-input {
    height: 40px !important;
  }

  // .ant-select-dropdown {
  //   top: 30px !important;
  //   left: 10px !important;
  // }

}

.ant-select-status-error {
  border: 1px solid red !important;
}

// .select-mes:focus,
// .select-mes:hover {
//   border: 1px solid var(--main-bg-btn-color) !important;
// }

.select-mes:disabled {
  color: rgba(0, 0, 0, 0.88) !important;
  background: rgba(0, 0, 0, 0.04) !important;
}

.form-filter-search {
  background: #FAFAFA;
  border: 1px dashed #6C8BA6;
  border-radius: 8px;
  margin-top: 12px;
  margin-left: 0px;
  padding: 20px 12px 10px;
  // max-height: 168px;
  overflow: auto;

  .input-mes {
    height: 32px;
    padding-top: 3px;

    .ant-picker-suffix {
      color: #9B9B9B;
    }

    // .ant-picker-clear {
    //   padding-top: 11px;
    // }
  }

  .label-input {
    color: #333333 !important;
    font-family: "robotoMeium";
  }

  .select-mes {
    height: 32px;
    padding: 0px;

    .ant-select-selector {
      height: 100% !important;
      padding: 2px 11px !important;

      input {
        height: 30px !important;
      }
    }

    .ant-select-selection-item {
      line-height: 24px;
    }

    .ant-select-selection-placeholder {
      line-height: 23px;
    }

    .ant-select-arrow {
      margin-top: -5px !important;
    }
  }

  .select-ams-multi-search {
    .ant-select-selector {
      padding: 2px 0px 2px 4px;
    }

    .ant-select-selection-overflow {
      max-height: 50px !important;
      overflow: auto !important;
      padding-right: 3px !important;
    }

    .ant-select-selection-overflow-item span:first-of-type {
      margin-left: 0px !important;
    }

    .ant-select-selector {
      padding-inline-end: 0px !important;
      border: 1px solid #BFBFBF !important;
      border-radius: 4px !important;
      padding: 1.2px 0px 1.2px 4px !important;
    }

    .ant-select-selection-item {
      height: 22px !important;
      line-height: 20px !important;
    }

    .ant-select-selection-placeholder {
      font-style: italic;
      color: rgb(0 0 0 / 28%);
    }


  }

  .create-date {
    .input-mes {
      width: 48%;
    }

    .line {
      width: 7%;
      text-align: center;
      line-height: 26px;
      font-size: 25px;
      color: #9B9B9B;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .btn-apply {
    // cursor: pointer;
    background: #286BA6;
    color: #fff !important;

    img {
      margin-right: 6px;
    }
  }

  .btn-refresh {
    border: 1px solid #286BA6;
    color: #286BA6;

    img {
      margin-right: 6px;
    }
  }

  .btn-apply-refresh {
    text-align: right;
    margin-top: 30px;
  }

}

.filter-button {
  font-family: 'robotoMeium' !important;
  min-width: 100px;

  &.active {
    background: #286BA6 !important;
    color: #fff !important;
  }
}

// .form-filter-search .select-mes .ant-select-selector {
//   padding: 2px 11px !important;
// }

// .select-mutil-user {
//   .ant-select-selector {
//     // min-height: 40px;
//     max-height: 60px;
//     overflow-y: auto;
//     border-radius: 4px;
//   }

//   .ant-select-selector:hover {
//     border: 1px solid #6c8ba6;
//   }
// }
.field-money-table {
  .custom-input-mask-wrapper input {
    border: none !important;
    padding: 5px 2px !important;
    color: #000000E0 !important;
    text-align: end;
  }

  .custom-input-mask-wrapper input:hover {
    background-color: inherit !important;
  }

  .custom-input-mask-wrapper input:disabled {
    background-color: inherit !important;
    cursor: context-menu;
  }
}

.ant-select-dropdown .ant-checkbox-wrapper {
  padding: 5px 0 !important;
  font-weight: 500;
}

.div_input_disabled {
  overflow: hidden;
  padding: 9px 11px;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.04);
  text-overflow: ellipsis;
  border-color: #d9d9d9;
}

@media (max-width: 1367px) {
  .form-filter-search .ant-btn {
    padding: 2px 13px !important;
  }
}

.form-filter-search
{
    padding-top: 0.5rem !important;
    padding-bottom: unset !important;
    padding-left: unset !important;
    padding-right: unset !important;
}