.breadcrumb-link-inactive {
    color: #9B9B9B;
    font-weight: 400;
}

.breadcrumb-link {
    color: #9B9B9B;
    font-weight: 400;
    a:hover {
        color: #286BA6;
    }
}

.breadcrumb-text {
    color: #286BA6;
    font-weight: 700;
}
