.paging {
  border: 1px solid #d9d9d9;
  border-top: 0px;
}
.pagination-component {
  // margin-top: 20px;
  margin-bottom: 40px;
  height: 6vh;

  .row {
    background: #f2f2f2;
    width: 100%;
    margin: auto;
  }

  .pagination-left {
    width: 140px;
    padding: 8px 8px 8px 12px;
    background: #f2f2f2;
    border-radius: 8px;
    cursor: pointer;
    height: 38px;
    font-size: 14px;

    #dropdownMenuButton1 {
      .pageIndex {
        font-weight: 700;
        margin-top: 1px;
        color: #006699;
      }

      img {
        padding-left: 10px;
      }
    }

    .title {
      color: #8f92a1;
      margin-right: 5px;
    }

    .show {
      display: initial !important;
    }
  }

  .moreActionBoxComponent {
    .show {
      display: initial;
    }

    .dropdown-item:hover {
      background-color: #006699;
      color: #fff !important;
      cursor: pointer;
    }

    .dropdown-item {
      color: black !important;
      cursor: pointer;
    }
  }

  .pagination-right {
    float: right;
    padding: 0 0 0 10px;
    background: #f2f2f2;

    border-radius: 10px;
    font-size: 14px;
    line-height: 36px;

    span {
      padding-right: 10px;
      color: #8f92a1;
    }

    .btn-left {
      height: 38px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    so .btn-right {
      height: 38px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    button {
      height: 38px;
      cursor: pointer;
      width: 50px !important;
      background: #f2f2f2;
      border: 0.6px solid #f2f2f2;
    }
  }
}
