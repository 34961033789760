.ams-btn {
    display: flex;
    justify-items: center;
    align-items: center;
    border-radius: 2px;
    height: 32px;
    border: none;
    box-shadow: none;
    font-weight: 400;
    font-size: 14px;

    span {
        width: 100%;
    }
}

.ams-btn-primary-outline {
    color: #286BA6;
    border: 1px solid #286BA6;

    span {
        color: #286BA6;
    }
}

.ams-btn-primary {
    background-color: #286BA6;
    color: #fff;

    span {
        color: #fff;
    }
}

.ams-btn-default-outline {
    color: #606771;
    border: 1px solid #e3e3e3;

    span {
        color: #606771;
    }
}

.ams-btn-trans {
    background-color: transparent;
    color: #286BA6;
    height: 0;

    span {
        color: #286BA6;
    }
}

.ams-btn-icon {
    width: 32px;
    height: 32px;
    border: 1px solid #e3e3e3;
}

.ams-btn-img {
    padding: 8px !important;

    img {
        width: 100%;
    }
}

.ams-btn-cancel {
    color: #286BA6;
    border: 1px solid #286BA6;
    ;
}

.normal-w {
    width: 100px;
}

.action-btn {
    position: relative;

    ul {
        top: 40px;
        right: 0px;
        position: absolute;
        z-index: 10;
        width: 220px;


        li {
            list-style: none;
            border: 1px solid #286BA6;
            padding: 8px 12px;
            border-bottom: none;
            background: #fff;
            text-align: right;

            &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom: 1px solid #286BA6;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            a {
                color: #286BA6 !important;
                font-size: 14px;

                img {
                    margin-right: 8px;
                }
            }
        }
    }
}


.ant-picker .ant-picker-suffix {
    img {
        width: 12px;
        height: auto;
    }
}