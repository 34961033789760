.status-blue {
  background: #6c8ba6;
  color: #ffffff;
}

.status-not-create {
  background: #6c757d;
  color: #ffffff;
}

.status-purple {
  background-color: #ede7f6;
  color: #4527a0;
}

.status-green {
  background: #6abe39;
  color: #ffffff;
}

.status-yellow {
  background: #faad14;
  color: #ffffff;
}

.status-red {
  background-color: #ffe9ed;
  color: #f56565;
}

.status-gray {
  background: #E7E7E7;
  color: #9b9b9b;
}

.status-green-1 {
  background: #6ABE39;
  color: #fff;
}

.status-primary {
  background: #6C8BA6;
  color: #fff;
}

.status-blue,
.status-gray,
.status-green,
.status-green-1,
.status-purple,
.status-red,
.status-yellow,
.status-not-create,
.status-primary {
  text-align: center;
  min-height: 22px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  width: 100%;
  padding: 5px 8px;
  height: 32px;
  font-family: "robotoMeium";
}