.search-input {
    .icon-button {
        border-radius: 0;
        justify-content: center;
        padding: 0px;
        width: 40px;
        height: 40px;
        background: var(--main-bg-btn-color);
        border: 1px solid var(--main-bg-btn-color);
        cursor: pointer;
    }

    .icon-button:hover {
        background: var(--main-bg-btn-color-hover);
    }

    input {
        box-shadow: none;
    }

    input:hover {
        box-shadow: none;
    }

    input::placeholder {
        font-style: italic;
    }
}
.search-component {
    width: 40%;
    position: relative;
    padding: 0;

    // height: 8vh;
    // background: #ebf2ff;
    .search-input-component {
        height: 42px !important;
        background: #ffffff;
        font-size: 14px;
        font-style: italic;
        border-radius: 6px;
        padding-right: 40px;
    }

    .search-icon {
        background: #006699;
        cursor: pointer;
        border-radius: 0%;
    }

    .input-group-text {
        background: #006699;
        cursor: pointer;
        border-radius: 0%;
    }

    .input-group-text:hover {
        background: #407590;
        cursor: pointer;
    }

    .box {
        padding: 5px 0px !important;
        // width: 65% !important;
        margin: 0 auto !important;

        .dropdown {
            width: 437px;
        }

        .search-button {
            height: 40px !important;
            background: #006699 !important;
            color: white !important;
            font-weight: 700 !important;
            font-size: 16px !important;
            width: 40px;
            float: left;
            z-index: 2;

            img {
                position: relative;
                left: 0px;
                top: -1px;
            }
        }

        .gray-button-color {
            height: 42px !important;
            background: #474554 !important;
            color: white !important;
            font-weight: 400 !important;
            font-size: 18px !important;
            width: 90%;
            float: left;
        }

        // .container-fluid {
        //   padding: 0;
        // }
    }

    // new input search
    .input-search-new {
        position: relative;
    }
}
