.nav-base-mes {
    width: 100%;
    overflow: auto;
    padding: 0 14px;
    .nav-item {
        cursor: pointer;
        width: auto;
        padding: 0 20px;
        font-weight: 700;
        font-size: 14px;
        color: #212121;
        .active {
            padding-bottom: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #006699;
            border-bottom: 2px solid #006699;
        }
    }
}
